@import 'normalize.css';

#root,
body,
html {
  height: 100%;
  overflow-x: auto;
  overscroll-behavior: none;
  outline: none !important;
  border-color: transparent !important;
  -webkit-tap-highlight-color: transparent;
}

root:focus-visible {
  outline: none !important;
}

body {
  font-family: Montserrat, system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: var(--color_0) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a:focus,
input:focus,
p:focus,
div:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  text-decoration: none;
  outline: 0;
}

a:visited {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
  outline: 0;
}

a:active {
  text-decoration: none;
  outline: 0;
}

button {
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
}

input,
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

* {
  box-sizing: border-box;
}

/* 暗黑 */
:root {
  --color_0: #0d0f12;
  --color_10: #121418;
  --color_100: #78808e;
  --color_110: #828a97;
  --color_120: #9197a3;
  --color_130: #a1a6b0;
  --color_140: #adb2bb;
  --color_150: #bec2c8;
  --color_160: #cbcfd4;
  --color_170: #dbdde1;
  --color_180: #eeeff1;
  --color_190: #fafafa;

  --color_20: #1e2126;
  --color_200: #ffffff;
  --color_30: #292d33;
  --color_40: #33373e;
  --color_50: #3f444c;
  --color_60: #4a4f58;
  --color_70: #555b65;
  --color_80: #5f6671;
  --color_90: #6a717d;

  --primary_color_100: #f9e2bc;
  --primary_color_200: #f1d2a1;
  --primary_color_300: #e9bf82;
  --primary_color_400: #dda556;
  --primary_color_50: #fff3dd;
  --primary_color_500: #c98b34;
  --primary_color_600: #b67b29;
  --primary_color_700: #a0691d;
  --primary_color_800: #8b5810;
  --primary_color_900: #754604;
  --primary_color_linear: linear-gradient(136deg, #fff0d3 0%, #d5953b 100%);
  --green-color-50: #e2fcf0;
  --green-color-100: #94e6c1;
  --green-color-200: #62dba4;
  --green-color-300: #31cf87;
  --green-color-400: #00c46b;
  --green-color-500: #02b061;
  --green-color-600: #048e50;
  --green-color-700: #067343;
  --green-color-800: #085936;
  --green-color-900: #0a3c28;

  --red-color-50: #fee9eb;
  --red-color-100: #fbb3bd;
  --red-color-200: #f98d9c;
  --red-color-300: #f86a7d;
  --red-color-400: #f6465d;
  --red-color-500: #d13d51;
  --red-color-600: #ac3545;
  --red-color-700: #8d2d3b;
  --red-color-800: #6c2531;
  --red-color-900: #471d25;
  --tv-color-pane-background: #F0F0F0 !important;
  --mobile-width: 768px;

  --mobile-desc-font-size: 12px;

  // padding
  --base-24: 24px;
  --base-12: 12px;

  --comp-grad-yellow: linear-gradient(180deg, #FFEBAF 0%, #FCBE00 100%);
  --comp-yellow: #FFD600
}

/* light */
[data-theme='light'] {
  --color_0: #f5f5f8;
  --primary-text-color: #797b96;
}

.Toastify__toast--error {
  border-radius: 8px;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

button[disabled] {
  cursor: not-allowed;
}

.c50 {
  color: var(--color_50);
}

.c80 {
  color: var(--color_80);
}

.c90 {
  color: var(--color_90);
}

.c100 {
  color: var(--color_100);
}

.c110 {
  color: var(--color_110);
}

.c200 {
  color: var(--color_200);
}

.c300 {
  color: var(--primary_color_300);
}

.cp300 {
  color: var(--comp-yellow);
}

.c120 {
  color: var(--color_120);
}

.c160 {
  color: var(--color_160);
}

.c190 {
  color: var(--color_190);
}

.bgc10 {
  background: var(--color_10);
}

.bgc20 {
  background: var(--color_20);
}

.cMain {
  color: var(--primary_color_300);
}

.color-green-400 {
  color: var(--green-color-400);
}

.color-red-400 {
  color: var(--red-color-400);
}

.f32 {
  font-size: 32px;
}

.f28 {
  font-size: 28px;
}

.f20 {
  font-size: 20px;
}

.f18 {
  font-size: 18px;
}

.f16 {
  font-size: 16px;
}

.f14 {
  font-size: 14px;
}

.f12 {
  font-size: 12px;
}

.fw1 {
  font-weight: 200;
}

.fw2 {
  font-weight: 400;
}

.fw3 {
  font-weight: 600;
}

.fw7 {
  font-weight: 700;
}

.bc10 {
  background: var(--color_10);
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;

}

.tr {
  text-align: right;

}

.cp {
  cursor: pointer;
}

.hover:hover {
  opacity: 0.8;
}

.p-8 {
  padding: 8px;
}

.df {
  display: flex;
}

.fdc {
  flex-direction: column;
}

.fdr {
  flex-direction: column;
}

.jcfs {
  justify-content: flex-start;
}

.jcfe {
  justify-content: flex-end;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.fl1 {
  flex: 1;
}

.gap8 {
  gap: 8px;
}

.br4 {
  border-radius: 4px;
}

.br8 {
  border-radius: 8px;
}

.br12 {
  border-radius: 12px;
}

.br16 {
  border-radius: 16px;
}

.tr {
  text-align: right;
}

.dib {
  display: inline-block;
}

.ovh {
  overflow: hidden;
}

//等宽字体
.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.pen {
  pointer-events: none;
}

.ant-input:focus,
.ant-input:hover {
  border: 1px solid var(--color_50);
}

.ant-picker-input>input::placeholder {
  color: var(--color_90) !important;
}

// .ant-picker-separator,
.ant-picker-suffix {
  color: var(--color_190) !important;
}

.ml-2 {
  margin-left: 2px;
}

.ml-4 {
  margin-left: 4px;
}

.g-8 {
  gap: 8px;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.lh18 {
  line-height: 18px;
}

.lh20 {
  line-height: 20px;
}

.lh22 {
  line-height: 22px;
}

.lh24 {
  line-height: 24px;
}

.lh30 {
  line-height: 30px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

a.tdn {
  text-decoration: underline;
}

.f40 {
  font-size: 40px;
}

.f24 {
  font-size: 24px;
}

.f0 {
  font-size: 0;
}

.pl-8 {
  padding-left: 8px;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.wb {
  word-wrap: break-word;
}

.border-b-dashed {
  border-bottom: 1px dashed var(--color-110, #828a97);
}

.rotate270 {
  transform: rotate(270deg);
}

.nowrap {
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .f28 {
    font-size: 24px;
  }

  .f18 {
    font-size: 14px;
  }

  .__floater {
    left: 3% !important;
    bottom: 0;
  }

  td.chart-markup-table.price-axis-container {
    pointer-events: none;
    display: none;
  }
}

.__floater {
  position: fixed !important;
  top: calc(50% - 234px) !important;
}

.Toastify-debug-button {
  border-bottom: 1px dashed var(--color-110, #828a97);
}

.cp_h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.cp_h3 {
  font-size: 24px;
  color: var(--color_200);
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.cp_h4 {
  font-size: 20px;
  // color: var(--color_200);
  font-style: normal;
  line-height: 26px;
  font-weight: 600;
}

.cp_h5 {
  font-size: 16px;
  color: var(--color_200);
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.cp_desc {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color_120);

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .cp_h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .cp_h3 {
    line-height: 22px;
  }

  .cp_h4 {
    font-size: 20px;
  }

  .cp_h5 {
    font-size: 16px;
    line-height: 26px;
  }

  .cp_desc {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }

  .cp_h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .cp_h3 {
    line-height: 22px;
  }

  .cp_h4 {
    font-size: 20px;
  }

  .cp_h5 {
    font-size: 16px;
    line-height: 26px;
  }

  .cp_desc {
    font-size: 12px;
    line-height: 18px;
  }
}

.page_loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color_190);
  gap: 24px;
  background: rgba(0, 0, 0, 0.3);

  .process_wrap {
    width: 150px;
    background-color: var(--color_40);
    border-radius: 4px;
    overflow: hidden;

    .process {
      width: 0;
      height: 4px;
      background: var(--primary_color_linear);
      animation: progress 5s linear infinite;
      border-radius: 4px;
    }

    @keyframes progress {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }
    }
  }
}

table {
  border-spacing: 0px !important;
  width: 100% !important;
}

.ant-table-thead>tr>th {
  padding: 16px 16px;
}

.ant-btn-primary {
  background: var(--primary_color_linear) !important;
}

.ant-drawer {
  outline: none;
}

.ant-btn:not(:disabled):focus-visible {
  outline: none;
}

.ant-btn-default:hover {
  border-color: var(--color_70);
}

.tradingview-widget-copyright {
  display: none !important;
}

.chart-markup-table {
  display: none !important;
}

.chart-gui-wrapper {
  display: none !important;
}

:where(.css-1ari5fi).ant-table-wrapper .ant-table-row-indent+.ant-table-row-expand-icon {
  display: none !important;
}

:where(.css-1ari5fi).ant-table-wrapper .ant-table-row-expand-icon {
  display: none !important;

}

tr.ant-table-row.ant-table-row-level-1 {
  background: var(--color_20);

  td {
    border-top: 1px dashed var(--color_20);
  }
}

span.ant-table-row-indent.indent-level-1 {
  display: none;
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  display: none;
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
  display: none;

}

/* 持仓页脚注释 */
:where(.css-1ari5fi).ant-table-wrapper .ant-table-row-indent+.ant-table-row-expand-icon {
  display: none !important;
}

li.ant-pagination-item.ant-pagination-item-active {
  font-weight: 600;
  background-color: transparent;
  border-color: var(--color_190);
  color: red
}

li.ant-pagination-item.ant-pagination-item-active:hover {
  border-color: var(--primary_color_300);
}

ul.ant-pagination.css-1ari5fi {
  color: var(--color_190);
}

button.ant-pagination-item-link {
  color: var(--color_190)
}

.ant-pagination-item {
  color: var(--color_190)
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-item-active a {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-item a {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-item a:hover {
  color: var(--primary_color_300)
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-next .ant-pagination-item-link {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--color_190);
}

.ant-select.ant-pagination-options-size-changer.css-1ari5fi.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  display: none;
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-options-quick-jumper input {
  background-color: transparent;
  color: var(--color_190);
}

:where(.css-1ari5fi).ant-pagination .ant-pagination-options-quick-jumper input:hover {
  border-color: var(--primary_color_300);
}

/*----dev----*/
// :where(.css-dev-only-do-not-override-1ari5fi) 
ul.ant-pagination.css-dev-only-do-not-override-1ari5fi {
  color: var(--color_190);
}

:where(.css-dev-only-do-not-override-1ari5fi) .ant-table-wrapper .ant-table-row-indent+.ant-table-row-expand-icon {
  display: none !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-item a {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-item a:hover {
  color: var(--primary_color_300)
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--color_190);
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--color_190);
}

.ant-select.ant-pagination-options-size-changer.css-dev-only-do-not-override-1ari5fi.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  display: none;
}

.ant-pagination .ant-pagination-options-quick-jumper input {
  background-color: transparent;
  color: var(--color_190);
}

.ant-pagination-options-quick-jumper input:hover {
  border-color: var(--primary_color_300);
}

// :where(.css-dev-only-do-not-override-1ari5fi) 
/* 持仓页脚注释 */

.ant-tabs-nav::before {
  visibility: hidden;
}

/* starts with antd */
// .ant-pagination .ant-pagination-options-quick-jumper input {
//   background-color: #121418;
//   border-color: white !important;
// }

// .ant-pagination .ant-pagination-item a {
//   color: #9197a3;
// }

// .ant-pagination .ant-pagination-item-active {
//   background-color: #121418;
//   border-color: white !important;
// }

// .ant-pagination .ant-pagination-item-active a {
//   color: white;
// }

// .ant-pagination .ant-pagination-item-active:hover a {
//   color: white;
// }

// blp页脚注释
:where(.css-dev-only-do-not-override-1rfyybl).ant-table-wrapper .ant-table-pagination-right {
  justify-content: center;
}